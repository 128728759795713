import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

/**
 * Markdown table of contents component to display table of contents if enabled;
 * @method getTableOfContents
 * @param  {object}             props Component values {@see markdown.propTypes};
 * @return {JSX.Element | null}       Returns the JSX component or null;
 */
const getTableOfContents = props => {
  //If statement to check if table of contents is enabled;
  if (props.enable) {
    return (
      <Fragment>
        <p>
          <strong>Table of Contents</strong>
        </p>
        <div dangerouslySetInnerHTML={{ __html: props.tableOfContents }}></div>
      </Fragment>
    ) //end return
  } else {
    return null
  } //end if
} //end getTableOfContents

export default getTableOfContents

getTableOfContents.propTypes = {
  enable: PropTypes.bool,
  tableOfContents: PropTypes.string,
} //end propTypes
