import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import GetTableOfContents from './markdown-table-of-contents.js'

/**
 * Markdown component to display table of contents and content;
 * @method markdown
 * @param  {object}           props Component values {@see markdown.propTypes};
 * @return {JSX.Element}            Returns the JSX component;
 */
const markdownContent = props => {
  // If statement providing default enableTableOfContentsable value if missing;
  const enableTableOfContents = props.enableTableOfContents
    ? props.enableTableOfContents
    : false
  return (
    <div className="type-block type-body">
      <GetTableOfContents
        enable={enableTableOfContents}
        tableOfContents={props.tableOfContents}
      />
      <div dangerouslySetInnerHTML={{ __html: props.pageContent }}></div>
    </div>
  ) //end return
} //end markdownContent

export default markdownContent

markdownContent.propTypes = {
  enableTableOfContents: PropTypes.bool,
  tableOfContents: PropTypes.string,
  pageContent: PropTypes.string.isRequired,
} //end propTypes
