import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import ReadyToWork from '../components/readyToWork'
import MarkdownContent from '../components/markdown-content.js'
import { withPrefix } from 'gatsby'
import PropTypes from 'prop-types'

/**
 * Template for markdown files to by used in creatPage function {@see ../../gatsby-node.js};
 * @method markdownTemplate
 * @param  {object}         pageContext Object of deconstructed props;
 * @return {JSX.Element}                Returns the JSX component;
 */
function markdownTemplate({ pageContext }) {
  return (
    <Layout>
      <Helmet
        title={pageContext.metaTitle}
        meta={[
          {
            name: 'description',
            content: pageContext.metaDescription,
          },
        ]}
      />
      <header className="hero">
        <div
          className="hero__inner hero__inner--overlay"
          style={{
            backgroundImage: `url(${withPrefix('images/home-hero.jpg')})`,
          }}
        >
          <div className="hero-content">
            <h1 className="type-title type-uppercase _has-subhead">
              {pageContext.title}
            </h1>
          </div>
        </div>
        <svg
          className="signpost-center"
          viewBox="0 0 8 74"
          width="8"
          height="74"
        >
          <use xlinkHref="#icon-arrow-down" />
        </svg>
      </header>

      <section className="wrapper-chunky">
        <div className="text-align--left">
          <div className="container-narrow island-large">
            <MarkdownContent
              enableTableOfContents={pageContext.enableTableOfContents}
              tableOfContents={pageContext.tableOfContents}
              pageContent={pageContext.content}
            />
          </div>
        </div>
      </section>
      <ReadyToWork />
    </Layout>
  ) //end return
} //end markdownTemplate

export default markdownTemplate

markdownTemplate.propTypes = {
  metaTitle: PropTypes.string,
  metaDescription: PropTypes.string,
  title: PropTypes.string,
  updateDate: PropTypes.string,
  enableTableOfContents: PropTypes.bool,
  tableOfContents: PropTypes.string,
  content: PropTypes.string,
} //end propTypes
